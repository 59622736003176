import React from 'react'
import './brandAssets.css'

import primaryDarkSVG from './../../assets/images/brandAssets/ethernal-primary-dark.svg'
import primaryLightSVG from './../../assets/images/brandAssets/ethernal-primary-light.svg'

import iconDarkSVG from './../../assets/images/brandAssets/ethernal-icon-dark-transparent.svg'
import iconLightSVG from './../../assets/images/brandAssets/ethernal-icon-light-transparent.svg'

import iconDarkPNG from './../../assets/images/brandAssets/ethernal-icon-64x64-dark.png'
import iconLightPNG from './../../assets/images/brandAssets/ethernal-icon-64x64-light.png'

import iconDarkTransparentPNG from './../../assets/images/brandAssets/ethernal-icon-64x64-dark-transparent.png'
import iconLightTransparentPNG from './../../assets/images/brandAssets/ethernal-icon-64x64-light-transparent.png'

import iconDarkTransparent1080PNG from './../../assets/images/brandAssets/ethernal-icon-1080x1080-dark-transparent.png'
import iconLightTransparent1080PNG from './../../assets/images/brandAssets/ethernal-icon-1080x1080-light-transparent.png'

import iconDark1080PNG from './../../assets/images/brandAssets/ethernal-icon-1080x1080-dark.png'
import iconLight1080PNG from './../../assets/images/brandAssets/ethernal-icon-1080x1080-light.png'

import primaryDarkPNG from './../../assets/images/brandAssets/ethernal-primary-dark.png'
import primaryLightPNG from './../../assets/images/brandAssets/ethernal-primary-light.png'

 


function BrandBody() {
  return (
    <section className='brandBody_body'>
        <div className='container brandBody_main'>

            

    
<p className='brandBody_title'>Download our logos here.</p>

 <div className='brandBody_wrapper table-responsive'>
<table className="table table-bordered">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">SVG</th>
      <th scope="col">PNG</th>
       <th scope="col">PNG</th>
    </tr>
  </thead>
  <tbody>
     <tr>
      <th scope="row">Primary Logos</th>
      <td> </td>
      <td> </td>
       <td> </td>
     
    </tr>
   
    <tr>
      <th scope="row">Ethernal - Primary - Light</th>
      <td>
          <a href='/brandAssets/ethernal-primary-light.svg' download='ethernal-primary-light.svg'>
                <img src={primaryLightSVG} alt="primaryLightSVG" className='brand-img-table'/>
              </a>
        </td>
      <td>
        <a href='/brandAssets/ethernal-primary-light.png' download='ethernal-primary-light.png'>
                <img src={primaryLightPNG} alt="primaryLightPNG" className='brand-img-table'/>
              </a>
        </td>
         <td>
         
        </td>
     
    </tr>
    <tr>
      <th scope="row">Ethernal - Primary - Dark</th>
      <td> 
       {/* download="ethernal-primary-dark.svg" target="_blank" rel="noopener noreferrer"*/}
         <a href='/brandAssets/ethernal-primary-dark.svg' download='ethernal-primary-dark.svg'>
                <img src={primaryDarkSVG} alt="primaryDarkSVG" className='brand-img-table'/>
              </a>

        </td>
      <td>
         <a href='/brandAssets/ethernal-primary-dark.png' download='ethernal-primary-dark.png'>
                <img src={primaryDarkPNG} alt="primaryDarkPNG" className='brand-img-table'/>
              </a>
        </td>
         <td>
        
        </td>
     
    </tr>
     <tr>
      <th scope="row"> </th>
      <td>  </td>
      <td>  </td>
      <td>  </td>
     
    </tr>

      <tr>
      <th scope="row">Icons</th>
      <td>  </td>
      <td>  </td>
      <td>  </td>
     
    </tr>
      <tr>
      <th scope="row"> </th>
      <td>  </td>
      <td>PNG 64x64</td>
      <td>PNG 1080x1080</td>
     
    </tr>
     <tr>
      <th scope="row">Ethernal - Icon - Light</th>
      <td>
         
        </td>
      <td>
      <a href='/brandAssets/ethernal-icon-64x64-light.png' download='ethernal-icon-64x64-light.png'>
                <img src={iconLightPNG} alt="iconLightPNG" className='brand-img-table'/>
              </a>
        </td>
      <td>
      <a href='/brandAssets/ethernal-icon-1080x1080-light.png' download='ethernal-icon-1080x1080-light.png'>
                <img src={iconLight1080PNG} alt="iconLight1080PNG" className='brand-img-table'/>
              </a>
        </td>
     
    </tr>
     <tr>
      <th scope="row">Ethernal - Icon - Dark</th>
      <td>
       
        </td>
      <td>
       <a href='/brandAssets/ethernal-icon-64x64-dark.png' download='ethernal-icon-64x64-dark.png'>
                <img src={iconDarkPNG} alt="iconDarkPNG" className='brand-img-table'/>
              </a>
        </td>
       <td>
       <a href='/brandAssets/ethernal-icon-1080x1080-dark.png' download='ethernal-icon-1080x1080-dark.png'>
                <img src={iconDark1080PNG} alt="iconDark1080PNG" className='brand-img-table'/>
              </a>
        </td>
     
    </tr>

      <tr>
      <th scope="row">Ethernal - Icon - Transparent</th>
      <td>
         <a href='/brandAssets/ethernal-icon-dark-transparent.svg' download='ethernal-icon-dark-transparent.svg' className='brand-img-link'>
                <img src={iconDarkSVG} alt="iconDarkSVG" className='brand-img-table'/>
              </a>
        <a href='/brandAssets/ethernal-icon-light-transparent.svg' download='ethernal-icon-light-transparent.svg'>
                <img src={iconLightSVG} alt="iconLightSVG" className='brand-img-table'/>
              </a>
        </td>
      <td>
       <a href='/brandAssets/ethernal-icon-64x64-dark-transparent.png' download='ethernal-icon-64x64-dark-transparent.png' className='brand-img-link'>
                <img src={iconDarkTransparentPNG} alt="iconDarkTransparentPNG" className='brand-img-table'/>
              </a>
        <a href='/brandAssets/ethernal-icon-64x64-light-transparent.png' download='ethernal-icon-64x64-light-transparent.png'>
                <img src={iconLightTransparentPNG} alt="iconLightTransparentPNG" className='brand-img-table'/>
              </a>
        </td>
       <td>
       <a href='/brandAssets/ethernal-icon-1080x1080-dark-transparent.png' download='ethernal-icon-1080x1080-dark-transparent.png'>
                <img src={iconDarkTransparent1080PNG} alt="iconDarkTransparent1080PNG" className='brand-img-table'/>
              </a>
        <a href='/brandAssets/ethernal-icon-1080x1080-light-transparent.png' download='ethernal-icon-1080x1080-light-transparent.png'>
                <img src={iconLightTransparent1080PNG} alt="iconLightTransparent1080PNG" className='brand-img-table'/>
              </a>
        </td>
     
    </tr>

 
    {/* <tr>
      <th scope="row">3</th>
      <td colspan="2">Larry the Bird</td>
      <td>@twitter</td>
    </tr> */}
  </tbody>
</table>


 </div>

        </div>
    </section>
  )
}

export default BrandBody
