import React from 'react'
import './contact.css';

function Contact() {
  return (
    <section id="contact" className='contact_section'>
        <div className="container">

   <div className='contact_footer-footer'>


             
        <div className='contact_footer-footer-heading'>
         
            <h1>
              Contact
            </h1>
            
             <p>Send us a message about you or your project and we will get back to you ASAP!</p>
        </div>
       

                        <a href="mailto:info@ethernal.tech" className='contact_footer-btn-new no-wrap'>
                          <span>Get in touch</span>
                         </a>
           
     
    </div> 


            </div>

       </section>
  )
}

export default Contact
