import React,{useState, useEffect} from 'react'
// RiMenu3Line
import {RiMenuLine , RiCloseLine} from 'react-icons/ri';
 import { Link as LinkScroll} from 'react-scroll';
 import { Link as LinkPage} from 'react-router-dom'

import './pageNav.css'
 
 import logo from './../../assets/images/logo/ethernal-logo-mark-white-original.svg'

const PageNav = (props) => {

   const [navbar, setNavbar] = useState(true);
    //  const [activeLink, setActiveLink] = useState('home'); 
     const [scrollDirection, setScrollDirection] = useState('none'); 
     const [isMobile, setIsMobile] = useState(window.innerWidth <= 991.98);

    useEffect(() => {
    const changeBackground = () => {
      const currentScrollY = window.scrollY;

      if (!isMobile) {
        if (currentScrollY >= 0.01 && currentScrollY > scrollDirection) {
          setNavbar(false);
          setScrollDirection(currentScrollY);
        } else if (currentScrollY < scrollDirection || currentScrollY === 0) {
          setNavbar(true);
          setScrollDirection(currentScrollY);
        }
      }
    };

    

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991.98);
    };

    
    changeBackground();

    
    window.addEventListener('scroll', changeBackground);
    window.addEventListener('resize', handleResize);

    
    return () => {
      window.removeEventListener('scroll', changeBackground);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, scrollDirection]);



  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (

    
      <nav id='navbar' className={
        
    navbar
     ? 'navbar navbar-expand-lg navbar-light navbar-show' 
     : 'navbar navbar-expand-lg navbar-light navbar-hide'}>

  <div className="container-fluid">
    <div className='col pageNav-col1'>
         <LinkScroll className="navbar-brand" to="pageNav">
      <img src={logo} alt="ethernal-logo-mark" className='logo-nav'/></LinkScroll>  

    </div>
    <div className='col pageNav-col2'>
       <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">

              <LinkPage to={'/'} className="nav-link" activeClass="active">
             
                  Home
                  </LinkPage>   

        
        </li>

          <li className="nav-item">
                  <LinkScroll className="nav-link " to="contact" activeClass="active">Contact</LinkScroll> 
                
                </li>
       
        
      </ul>

       
    </div>

    </div>

    {/* <div className='col navbar24-col3'> */}
       <button className="navbar-toggler" >

      
        
         <div className='menu-icon-new' onClick={handleClick}>

            {click
          ? 
          (navbar
          ? <RiCloseLine color="#E040FB" size={27} />
          : <RiCloseLine color="#E040FB" size={27}  /> )

          : 
          (navbar
          ? <RiMenuLine color="#ffffff" size={27} />
          : <RiMenuLine color="#ffffff" size={27}  /> )
          }

         

          </div>
          
      
      <ul className={click ? 'nav-menu-new active' : 'nav-menu-new'}>
            
            <li className='nav-item-new'>

               <LinkPage 
               to={'/'} 
               className='nav-links-new'
               onClick={closeMobileMenu}>
                 Home
                  </LinkPage>  


            </li>

              <li className='nav-item-new'>
                          <LinkScroll
                            to='contact'
                            
                            className='nav-links-new nav-contact-new'
                            onClick={closeMobileMenu}
                          >
                            Contact
                          </LinkScroll>
                        </li>
           
            
          </ul>
    

    </button>

    {/* </div> */}
    
  </div>
</nav>
    
  
  )
}

export default PageNav