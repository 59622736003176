import React from 'react'
import './openPositions.css'
// import { Helmet } from 'react-helmet-async';

import PageNav from "../pageNav/PageNav";
import CareersHeader from '../careersHeader/CareersHeader';
import CareersBody from '../careersBody/CareersBody';
import Contact from '../contact/Contact';
import PageFooter from '../pageFooter/PageFooter';

function OpenPositions() {
  return (
    <section>
      {/* <Helmet>

         <title>Business Development Representative - Careers at Ethernal</title>
         <meta name="description" content="Business Development Representative - Zug, Switzerland / Belgrade, Serbia - Remote or Office - Part-time / Full-time" />
         <link rel="canonical" href="https://ethernal.tech/open-positions" />


    <meta property="og:title" content="Business Development Representative - Careers at Ethernal" />
    <meta
      property="og:description"
      content="Business Development Representative - Zug, Switzerland / Belgrade, Serbia - Remote or Office - Part-time / Full-time"
    />
    <meta
      property="og:image"
      content="https://ethernal.tech/share-careers-1200-630.png"
    />

    <meta
      property="og:image:secure_url"
      content="https://ethernal.tech/share-careers-1200-630.png"
    />

    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:alt" content="Business Development Representative - Careers at Ethernal" />

    <meta property="og:url" content="https://ethernal.tech/open-positions" />
    <meta property="og:site_name" content="Business Development Representative - Careers at Ethernal" />
    <meta property="og:type" content="website" />

    <meta name="twitter:title" content="Business Development Representative - Careers at Ethernal" />
    <meta
      name="twitter:description"
      content="Business Development Representative - Zug, Switzerland / Belgrade, Serbia - Remote or Office - Part-time / Full-time"
    />
    <meta
      name="twitter:image"
      content="https://ethernal.tech/share-careers-1200-630.png"
    />

    <meta name="twitter:image:alt" content="Business Development Representative - Careers at Ethernal" />
    <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}
      <PageNav/>
      <CareersHeader/>
     <CareersBody/>
     <Contact/>
       <PageFooter/>
  </section>
    
  )
}

export default OpenPositions
