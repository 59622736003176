import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    // useEffect(() => {
    //      Instantno skrolovanje bez efekta
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: 'auto',  Isključuje glatko skrolovanje
    //     });
    // }, [pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
