import React from 'react'
import { Link as LinkPage} from 'react-router-dom'
import './notFound.css'

import logo from './../../assets/images/logo/ethernal-logo-mark-purple-original.svg'

const NotFoundBody = () => {
  return (
    <section className='notFound_section'>
       
      <div className='container notFound_main'>

        <header className='notFound_header'>
               <LinkPage className="notFound_brand" to={'/'}>
      <img src={logo} alt="ethernal-logo-mark" className='notFound_img'/>
      </LinkPage>  
  
        </header>
        <div className='notFound_text_wrapper'>

          <h1>404 error</h1>
          <h2 className='notFound_first'>The page you’re looking for</h2>
          <h2 className='notFound_second'>is not found or doesn’t exist</h2>
          

         <LinkPage to={'/'} className='notFound_btn no-wrap'>
                     <span>Go to homepage</span>
        </LinkPage>  

        </div>

      </div>
      

    </section>
  )
}

export default NotFoundBody