import React from 'react'
import NotFoundBody from './NotFoundBody'

import './notFound.css'

const NotFound = () => {
  return (
    <section>
    
      <NotFoundBody/>
    

    </section>
  )
}

export default NotFound