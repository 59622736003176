import React from 'react'
import './brandAssets.css'

import PageNav from "../pageNav/PageNav";
import BrandHeader from '../brandAssets/BrandHeader';
import BrandBody from '../brandAssets/BrandBody';
import Contact from '../contact/Contact';
import PageFooter from '../pageFooter/PageFooter';



function BrandAssets() {
  return (
    <section>
         <PageNav/>
           <BrandHeader/>
              <BrandBody/> 
              <Contact/>
          <PageFooter/>
    </section>
  )
}

export default BrandAssets
