import React from 'react';
import { Link as LinkPage} from 'react-router-dom'
import { Link as LinkScroll} from 'react-scroll';
import './pageFooter.css';

import logo from './../../assets/images/logo/ethernal-logo-mark-white-original.svg'
 
 import LinkedInIcon from '../footer/LinkedInIcon';
 import XtwitterIcon from '../footer/XtwitterIcon';
 import InstagramIcon from '../footer/InstagramIcon';
 import TreadsIcon from '../footer/TreadsIcon';
 import FacebookIcon from '../footer/FacebookIcon';
 import FarcasterIcon from "../footer/FarcasterIcon"

const PageFooter = () => {


  return (

   
    <section className='careersFooter_section'>
        <div className="container" >

       <div className='footer-footer-links'>
          <div className='footer-footer-links_logo one-div-left'>
            
             {/* <p className='footer-logo-wrapper'> */}
             
                      <LinkScroll className='footer-logo-wrapper' to="pageNav">
                   <img className="footer-logo-img" src={logo} alt="ethernal-logo-mark" />
                   
                   </LinkScroll>   

                  
             {/* </p> */}


            
           
             <p className='footer-logo-paragraf'>We design and build high performance blockchain systems</p>
          </div>

          <div className='links-two-right'>

             <div className='footer-footer-linkedin_div'>
            <h4>Follow us:</h4>

         
                   <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.linkedin.com/company/ethernal-tech/" target="_blank" rel="noopener noreferrer">
                   
                   <LinkedInIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://twitter.com/ethernal_tech" target="_blank" rel="noopener noreferrer">
                   
                   <XtwitterIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.instagram.com/ethernal.tech" target="_blank" rel="noopener noreferrer">
                   
                   <InstagramIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.threads.net/@ethernal.tech" target="_blank" rel="noopener noreferrer">
                   
                   <TreadsIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.facebook.com/ethernal.tech" target="_blank" rel="noopener noreferrer">
                   
                   <FacebookIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://warpcast.com/ethernal-tech" target="_blank" rel="noopener noreferrer">
                   
                   <FarcasterIcon/>
              </a>
                   </p>
             
            
          </div> 


          <div className='footer-footer-links_div'>
            <h4>Company</h4>
            <p>Email: info@ethernal.tech</p>
            <p>Phone: +381 641323706</p>
            <p>Ethernal LLC, Nikolajevska 2</p>
            <p>Novi Sad, Serbia</p>
             <LinkPage to={'/brand-assets'} target="_blank" rel="noopener noreferrer" className='brand-assets'>
                                                     Brand Assets
                                                </LinkPage>
            
          </div>

          


          </div>

          

        </div>
        
        <div className='footer-footer-copyright'>
          <hr />
        <p>Copyright © 2023 Ethernal</p> 

       
          
        </div>

            </div>

       </section>

  )
}

export default PageFooter
