import React from 'react'
import './careersHeader.css'

function CareersHeader() {
  return (
    <section id='pageNav' className='openPosition_header'>
        <div className='container careersHeader_main'>
             <div>
           <h1 className='careersHeader_title'>Business Development Representative</h1>
      
        <p className='careersHeader_info'>Zug, Switzerland / Belgrade, Serbia - Remote or Office</p>
        <p className='careersHeader_info'>Part-time / Full-time</p>
     
        </div>
        </div>
    </section>
  )
}

export default CareersHeader
