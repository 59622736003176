import React from 'react'
import './careersBody.css'

function CareersBody() {
  return (
    <section className='openPosition_body'>
        <div className='container careersBody_main'>

             <div className='careersBody_wrapper'>

    
<p className='careersBody_title'>About the job</p>

<p className='careersBody_content'>Blockchain technologies are emerging as mainstream tech, slowly being adopted in security, finance, healthcare, and other industries. Implementing core solutions that are scalable, reliable, and secure is essential to enable the construction of an entire ecosystem on such infrastructures, spurring Web3 adoption. We are tackling these significant challenges by applying our cutting-edge expertise and engineering skills to the latest research results.</p>

<p className='careersBody_content'>If you enjoy a challenging environment, novel technologies, and wish to proactively contribute to market development, this is the right place for you!</p>

<p className='careersBody_title'>With us you will:</p>
<ul className='careersBody_list'>
  <li>Be part of a world-class blockchain infrastructure development team as a consultant, part-time, or eventually full time employee.</li>
  <li>Apply your knowledge to boost our presence in the market and further spur the adoption of blockchain technology in Web3 and traditional industries.</li>
  <li>Work in an environment that enables you to have close insights into cutting-edge R&D.</li>
  <li>Receive a competitive package.</li>
  <li>Work remotely or in our offices in Zug/ Belgrade.</li>
</ul>

<p className='careersBody_title'>Tasks and Responsibilities</p>

<ul className='careersBody_list'>
  <li>Develop a growth strategy focused on both financial gain and customer satisfaction.</li>
  <li>Arrange business development meetings with prospective clients.</li>
  <li>Conduct research to identify new markets and customer needs - identifying opportunities in target markets for your organization’s products and services.</li>
  <li>Promote the company’s products/services by addressing or predicting clients’ objectives.</li>
  <li>Establish an extended network of contacts and build trust among prospective clients.</li>
  <li>Build long-term relationships with new and existing customers.</li>
  <li>Keep up with the latest industry developments, including market positioning of corporate competitors.</li>
  <li>Assess client needs and the company’s ability to meet those needs.</li>
</ul>

<p className='careersBody_title'>Qualifications</p>

<ul className='careersBody_list'>
  <li>Minimum 2 years of proven working experience in the Web3 industry, as a business development manager, sales executive, or in a relevant role.</li>
  <li>Excellent analytical, networking, communication, and negotiation skills.</li>
  <li>Proficiency in English.</li>
  <li>Market knowledge.</li>
  <li>Ability to build rapport.</li>
  <li>Time management and planning skills.</li>
</ul>

 </div>

 <a 
//  href="mailto:info@ethernal.tech" 
href="mailto:info@ethernal.tech?subject=Business%20Development%20Representative%20-%20Careers%20at%20Ethernal" 
 className='careersBody_btn no-wrap'>
                          <span>Apply Now</span>
                         </a>

       

        </div>
    </section>
  )
}

export default CareersBody
