import React from 'react'
import './brandAssets.css'

function BrandHeader() {
  return (
    <section id='pageNav' className='brandHeader_header'>
        <div className='container brandHeader_main'>
             <div>
           <h1 className='brandHeader_title'>Brand Assets</h1>
      
        <p className='brandHeader_info'>Ethernal brand materials</p>
        {/* <p className='brandHeader_info'>You can find our brand assets like logos, typeface, colors, etc. here.</p> */}
     
        </div>
        </div>
    </section>
  )
}

export default BrandHeader
