import './App.css';

// import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Home, OpenPositions, BrandAssets, NotFound} from './components';
 import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
   <div>
      {/* <HelmetProvider> */}
     <BrowserRouter>
      <ScrollToTop />
      <Routes>
         <Route index element={<Home/>} />
         <Route path='/open-positions' element={<OpenPositions/>} />
          <Route path='/brand-assets' element={<BrandAssets/>} />
          <Route path='*' element={<NotFound/>} /> 
      </Routes>
      </BrowserRouter>
      {/* </HelmetProvider> */}
    
   </div>
  );
}

export default App;
